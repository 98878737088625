import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { BitrixUser } from "../types/Users";
import axios from "axios";

interface BitrixContextProps {
  usersList: BitrixUser[] | undefined;
}

const BitrixContext = createContext<BitrixContextProps | undefined>(undefined);

export const useBitrix = (): BitrixContextProps => {
  const context = useContext(BitrixContext);
  if (!context) {
    throw new Error("useBitrix must be used within a BitrixProvider");
  }
  return context;
};

interface BitrixProviderProps {
  children: ReactNode;
}

export const BitrixProvider = ({ children }: BitrixProviderProps) => {
  const [usersList, setUsersList] = useState<BitrixUser[]>();

  const initialize = useCallback(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/users`)
      .then((response) => setUsersList(response.data));
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const memoizedValue = useMemo(
    () => ({
      usersList,
    }),
    [usersList]
  );

  return (
    <BitrixContext.Provider value={memoizedValue}>
      {children}
    </BitrixContext.Provider>
  );
};
