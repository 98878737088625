import { Button, Popover, Stack, Typography } from "@mui/material";
import { AccountReport } from "../../types/Report";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import React from "react";

interface ReportStatusColumnProps {
  accountReport: AccountReport;
}

export default function ReportStatusColumn({
  accountReport,
}: ReportStatusColumnProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "error-popover" : undefined;

  if (accountReport.error) {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <Button aria-describedby={id} onClick={handleClick} sx={{ width: 10 }}>
          <ErrorIcon color="warning" />
        </Button>
        <Typography variant="body2">Click para detalles</Typography>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          sx={{ maxWidth: 300 }}
        >
          <Typography sx={{ p: 2 }}>{accountReport.errorMessage}</Typography>
        </Popover>
      </Stack>
    );
  } else {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        sx={{ height: "100%" }}
      >
        <CheckCircleIcon color="success" />
      </Stack>
    );
  }
}
