import * as React from "react";
import "./ReportsTable.css";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ReportsTableActionMenu from "./ReportsTableActionMenu";
import { useEffect } from "react";
import axios from "axios";
import { Report } from "../types/Report";
import ReportViewDialog from "./ReportView/ReportViewDialog";

export default function ReportsTable() {
  const [reports, setReports] = React.useState<Report[]>();
  const [reportViewOpen, setreportViewOpen] = React.useState(false);
  const [reportSelected, setReportSelected] = React.useState<Report>();

  useEffect(() => {
    const fetchReport = () => {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/reports`)
        .then((response) => setReports(response.data));
      // eslint-disable-next-line react-hooks/exhaustive-deps
    };

    fetchReport();

    const refreshInterval = setInterval(fetchReport, 10000);

    return () => clearInterval(refreshInterval);
  }, []);

  if (!reports) {
    return <p>Cargando...</p>;
  }

  const columns: GridColDef<(typeof reports)[number]>[] = [
    {
      field: "createdAt",
      type: "dateTime",
      headerName: "Fecha y hora",
      minWidth: 200,
      flex: 1,
      valueGetter: (value) => new Date(value),
    },
    {
      field: "status",
      headerName: "Estado",
      minWidth: 100,
      flex: 1,
    },
    {
      field: "reportPeriod",
      headerName: "Periodo",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "notes",
      headerName: "Notas",
      minWidth: 300,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => (
        <ReportsTableActionMenu
          Report={params.row}
          handleOpenView={() => {
            setReportSelected(params.row);
            setreportViewOpen(true);
          }}
        />
      ),
    },
  ];

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <DataGrid
          rows={reports}
          getRowId={(row) => row._id}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
            sorting: {
              sortModel: [{ field: "createdAt", sort: "desc" }],
            },
          }}
          pageSizeOptions={[10]}
          disableRowSelectionOnClick
          getRowHeight={() => "auto"}
          autoHeight
        />
      </Box>
      {reportSelected && (
        <ReportViewDialog
          open={reportViewOpen}
          report={reportSelected}
          setOpen={setreportViewOpen}
        />
      )}
    </>
  );
}
