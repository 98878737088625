import { IconButton } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import { Account } from "../types/Account";
import { useState } from "react";
import AccountsEditDialog from "./AccountsEditDialog";
import AccountsDeleteDialog from "./AccountsDeleteDialog";

interface AccountsTableActionMenuProps {
  Account: Partial<Account>;
}

export default function AccountsTableActionMenu({
  Account,
}: AccountsTableActionMenuProps) {
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  return (
    <>
      <IconButton
        aria-label="edit"
        color="primary"
        onClick={() => setEditDialogOpen(true)}
      >
        <ModeEditIcon />
      </IconButton>
      <IconButton
        aria-label="delete"
        color="error"
        onClick={() => setDeleteDialogOpen(true)}
      >
        <DeleteIcon />
      </IconButton>
      <AccountsEditDialog
        open={editDialogOpen}
        setOpen={setEditDialogOpen}
        Account={Account}
      />
      <AccountsDeleteDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        Account={Account}
      />
    </>
  );
}
