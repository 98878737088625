import { AccountReport } from "../types/Report";

export const ivaDebitoFiscal = (accountReport: AccountReport): number =>
  accountReport.data?.emitidos?.reduce((prev, curr) => {
    if (
      curr.tipo.includes("Factura A") ||
      curr.tipo.includes("201 -") ||
      curr.tipo.includes("Factura B") ||
      curr.tipo.includes("206 -") ||
      curr.tipo.includes("Factura C") ||
      curr.tipo.includes("211 -") ||
      curr.tipo.includes("Nota de Débito A") ||
      curr.tipo.includes("202 -") ||
      curr.tipo.includes("Nota de Débito B") ||
      curr.tipo.includes("207 -") ||
      curr.tipo.includes("Nota de Débito C") ||
      curr.tipo.includes("212 -")
    ) {
      return prev + curr.iva;
    } else if (
      curr.tipo.includes("Nota de Crédito A") ||
      curr.tipo.includes("203") ||
      curr.tipo.includes("Nota de Crédito B") ||
      curr.tipo.includes("208") ||
      curr.tipo.includes("Nota de Crédito C") ||
      curr.tipo.includes("213")
    ) {
      return prev - curr.iva;
    } else {
      return prev;
    }
  }, 0) * -1 || 0.0;

export const ivaCreditoFiscal = (accountReport: AccountReport): number =>
  accountReport.data?.recibidos?.reduce((prev, curr) => {
    if (
      curr.tipo.includes("Factura A") ||
      curr.tipo.includes("201 -") ||
      curr.tipo.includes("Factura B") ||
      curr.tipo.includes("206 -") ||
      curr.tipo.includes("Factura C") ||
      curr.tipo.includes("211 -") ||
      curr.tipo.includes("Nota de Débito A") ||
      curr.tipo.includes("202 -") ||
      curr.tipo.includes("Nota de Débito B") ||
      curr.tipo.includes("207 -") ||
      curr.tipo.includes("Nota de Débito C") ||
      curr.tipo.includes("212 -")
    ) {
      return prev + curr.iva;
    } else if (
      curr.tipo.includes("Nota de Crédito A") ||
      curr.tipo.includes("203") ||
      curr.tipo.includes("Nota de Crédito B") ||
      curr.tipo.includes("208") ||
      curr.tipo.includes("Nota de Crédito C") ||
      curr.tipo.includes("213")
    ) {
      return prev - curr.iva;
    } else {
      return prev;
    }
  }, 0) || 0.0;

export const saldoIVAEstimado = (accountReport: AccountReport): number =>
  ivaDebitoFiscal(accountReport) +
  ivaCreditoFiscal(accountReport) +
  (accountReport.data?.saldoTecnico || 0.0) +
  (accountReport.data?.saldoLibre || 0.0);

export const ventas = (accountReport: AccountReport): number =>
  accountReport.data?.emitidos?.reduce((prev, curr) => {
    if (
      curr.tipo.includes("Factura A") ||
      curr.tipo.includes("201 -") ||
      curr.tipo.includes("Factura B") ||
      curr.tipo.includes("206 -") ||
      curr.tipo.includes("Factura C") ||
      curr.tipo.includes("211 -") ||
      curr.tipo.includes("Nota de Débito A") ||
      curr.tipo.includes("202 -") ||
      curr.tipo.includes("Nota de Débito B") ||
      curr.tipo.includes("207 -") ||
      curr.tipo.includes("Nota de Débito C") ||
      curr.tipo.includes("212 -")
    ) {
      return prev + curr.impNetoGravado + curr.impNetoNoGravado;
    } else if (
      curr.tipo.includes("Nota de Crédito A") ||
      curr.tipo.includes("203") ||
      curr.tipo.includes("Nota de Crédito B") ||
      curr.tipo.includes("208") ||
      curr.tipo.includes("Nota de Crédito C") ||
      curr.tipo.includes("213")
    ) {
      return prev - curr.impNetoGravado + curr.impNetoNoGravado;
    } else {
      return prev;
    }
  }, 0) || 0.0;

export const impuestoDeterminadoTotal = (
  accountReport: AccountReport
): number => ventas(accountReport) * -(accountReport.alicuota / 100) || 0.0;

export const saldoIBB = (accountReport: AccountReport): number =>
  impuestoDeterminadoTotal(accountReport) +
  (accountReport.data?.saldoMendoza || 0.0) +
  (accountReport.data?.SICREBTotal || 0.0) +
  (accountReport.data?.SIRTACTotal || 0.0);
