import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useState } from "react";
import { useSnackbar } from "../providers/SnackbarContext";
import { MenuItem } from "@mui/material";
import { AccountType } from "../types/Account";

interface ReportsCreateDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReportsCreateDialog({
  open,
  setOpen,
}: ReportsCreateDialogProps) {
  const { openSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    await axios
      .post(`${process.env.REACT_APP_API_HOST}/report/process`, {
        dateFrom: formJson.dateFrom + "T00:00",
        dateUntil: formJson.dateUntil + "T00:00",
        type: formJson.type,
      })
      .then(() => {
        setLoading(false);
        openSnackbar(
          "El reporte fue creado, y se procesará en los siguientes minutos",
          "success"
        );
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        openSnackbar("Hubo un error al crear el reporte", "error");
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: onFormSubmit,
        }}
      >
        <DialogTitle>Crear un Reporte</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, ingresa el rango de fechas correspondiente al reporte que
            se desea crear
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="dateFrom"
            label="Fecha desde"
            type="date"
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            required
            margin="dense"
            name="dateUntil"
            label="Fecha hasta"
            type="date"
            fullWidth
            variant="standard"
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            required
            select
            margin="dense"
            name="type"
            label="Tipos a reportar"
            fullWidth
            variant="standard"
          >
            <MenuItem value="Todos">Todos</MenuItem>
            <MenuItem value={AccountType.Monotributista}>
              {AccountType.Monotributista}
            </MenuItem>
            <MenuItem value={AccountType.ResponsableInscripto}>
              {AccountType.ResponsableInscripto}
            </MenuItem>
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Crear
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
