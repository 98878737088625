import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Account } from "../types/Account";
import { useSnackbar } from "../providers/SnackbarContext";
import { useAccount } from "../providers/AccountContext";
import { useState } from "react";
import axios from "axios";

interface AccountsDeleteDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  Account: Partial<Account>;
}

export default function AccountsDeleteDialog({
  open,
  setOpen,
  Account,
}: AccountsDeleteDialogProps) {
  const { deleteAccount } = useAccount();
  const { openSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    await axios
      .post(`${process.env.REACT_APP_API_HOST}/account/delete`, {
        id: Account.id,
      })
      .then(() => {
        deleteAccount(Account.id as string);
        setLoading(false);
        openSnackbar("Se eliminó una cuenta con éxito.", "success");
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        openSnackbar("Hubo un error al eliminar la cuenta", "error");
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: onFormSubmit,
        }}
      >
        <DialogTitle>Eliminar Clave Fiscal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro que deseas eliminar la clave fiscal{" "}
            {Account.claveFiscal}?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button color="error" type="submit" disabled={loading}>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
