export enum AccountType {
  Monotributista = "Monotributista",
  ResponsableInscripto = "Responsable Inscripto",
}

export type Account = {
  id: string;
  claveFiscal: number;
  password: string;
  cuit: number;
  name: string;
  type: AccountType;
  alicuota: number;
  responsible: string;
};
