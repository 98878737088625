import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Report, ReportStatus } from "../../types/Report";
import ReportViewTable from "./ReportViewTable";
import axios from "axios";
import CachedIcon from "@mui/icons-material/Cached";
import { CircularProgress, Stack, Typography } from "@mui/material";

interface ReportViewDialogProps {
  open: boolean;
  report: Report;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function ReportViewDialog({
  open,
  report,
  setOpen,
}: ReportViewDialogProps) {
  const [currentReport, setCurrentReport] = React.useState<Report>();

  const handleClose = () => {
    setOpen(false);
  };

  const refreshReport = () => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/report?reportId=${report._id}`)
      .then((response) => {
        setCurrentReport(response.data);
      });
  };

  React.useEffect(() => {
    if (open) {
      axios
        .get(`${process.env.REACT_APP_API_HOST}/report?reportId=${report._id}`)
        .then((response) => {
          setCurrentReport(response.data);
        });
    } else {
      setCurrentReport(undefined);
    }
  }, [report, open]);

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              // minWidth: "1000px",
              minWidth: "95%",
            },
          },
        }}
      >
        <DialogTitle>Datos del reporte - {report.reportPeriod}</DialogTitle>
        <DialogContent>
          {!currentReport ? (
            <Stack justifyContent="center" alignItems="center" gap={2} p={5}>
              <CircularProgress />
              <Typography maxWidth={300} textAlign="center">
                Cargando información del reporte. Esto puede demorar unos
                minutos...
              </Typography>
            </Stack>
          ) : (
            <>
              {currentReport.status !== ReportStatus.READY && (
                <Button
                  variant="contained"
                  endIcon={<CachedIcon />}
                  sx={{ mb: 2 }}
                  onClick={refreshReport}
                >
                  Refrescar
                </Button>
              )}
              <ReportViewTable
                report={currentReport}
                handleRefresh={refreshReport}
              />
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cerrar</Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
