import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import axios from "axios";
import { useAccount } from "../providers/AccountContext";
import { useState } from "react";
import { useSnackbar } from "../providers/SnackbarContext";
import { MenuItem } from "@mui/material";
import { AccountType } from "../types/Account";
import { useBitrix } from "../providers/BitrixContext";

interface AccountsCreateDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function AccountsCreateDialog({
  open,
  setOpen,
}: AccountsCreateDialogProps) {
  const { addAccount } = useAccount();
  const { openSnackbar } = useSnackbar();
  const { usersList } = useBitrix();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    await axios
      .post(`${process.env.REACT_APP_API_HOST}/account/add`, formJson)
      .then((response) => {
        addAccount(response.data);
        setLoading(false);
        openSnackbar("Se agregó una cuenta con éxito.", "success");
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        openSnackbar("Hubo un error al crear la cuenta", "error");
      });
  };

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: onFormSubmit,
        }}
      >
        <DialogTitle>Agregar Clave Fiscal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Por favor, ingresa la nueva clave fiscal para que se tome en cuenta
            en el reporte.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            name="name"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            select
            margin="dense"
            name="type"
            label="Tipo"
            fullWidth
            variant="standard"
          >
            <MenuItem value={AccountType.Monotributista}>
              {AccountType.Monotributista}
            </MenuItem>
            <MenuItem value={AccountType.ResponsableInscripto}>
              {AccountType.ResponsableInscripto}
            </MenuItem>
          </TextField>
          <TextField
            required
            margin="dense"
            name="claveFiscal"
            label="Clave Fiscal"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            name="cuit"
            label="CUIT"
            type="number"
            fullWidth
            variant="standard"
          />
          <TextField
            required
            margin="dense"
            name="alicuota"
            label="Alicuota"
            type="number"
            fullWidth
            variant="standard"
            inputProps={{ step: "any" }}
          />
          <TextField
            required
            select
            margin="dense"
            name="responsible"
            label="Usuario responsable"
            fullWidth
            variant="standard"
          >
            {usersList
              ?.sort((a, b) => (a.NAME > b.NAME ? 0 : -1))
              .map((u) => (
                <MenuItem value={`${u.NAME} ${u.LAST_NAME}`}>
                  {`${u.NAME} ${u.LAST_NAME}`}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
