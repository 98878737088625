import { Button, Container, Stack } from "@mui/material";
import AccountsTable from "./AccountsTable";
import AddIcon from "@mui/icons-material/Add";
import AccountsCreateDialog from "./AccountsCreateDialog";
import { useState } from "react";

export default function AccountsPage() {
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  return (
    <Container>
      <Stack pt={2} pb={2} alignItems="flex-end">
        <Button
          startIcon={<AddIcon />}
          variant="contained"
          sx={{ width: 230 }}
          onClick={() => setCreateDialogOpen(true)}
        >
          Agregar clave fiscal
        </Button>
      </Stack>
      <AccountsTable />
      <AccountsCreateDialog
        open={createDialogOpen}
        setOpen={setCreateDialogOpen}
      />
    </Container>
  );
}
