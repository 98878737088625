import { IconButton, Stack, Typography } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import ReplayIcon from "@mui/icons-material/Replay";
import { AccountReport, Report, ReportStatus } from "../../types/Report";
import axios from "axios";
import { useSnackbar } from "../../providers/SnackbarContext";
import { useState } from "react";

interface ReportViewTableActionMenuProps {
  report: Report;
  accountReport: AccountReport;
  handleDownload: () => void;
  handleRefresh: () => void;
}

export default function ReportViewTableActionMenu({
  report,
  accountReport,
  handleDownload,
  handleRefresh,
}: ReportViewTableActionMenuProps) {
  const { openSnackbar } = useSnackbar();
  const [retrying, setRetrying] = useState(false);

  const retryProcess = () => {
    if (report.status === ReportStatus.WIP) {
      openSnackbar(
        "El reporte aún se está generando. Debe esperar a que se termine el proceso, y luego podrá reintentar individualmente.",
        "error"
      );
      return;
    }
    setRetrying(true);
    axios
      .post(`${process.env.REACT_APP_API_HOST}/report/single-process`, {
        reportId: report._id,
        claveFiscal: accountReport.claveFiscal,
        cuit: accountReport.cuit,
      })
      .then(() => {
        setTimeout(() => handleRefresh(), 5000);
        setTimeout(() => setRetrying(false), 10000);
        openSnackbar(
          `Se está reintentando el reporte de la cuenta ${accountReport.name}`,
          "success"
        );
      })
      .catch(() => {
        openSnackbar(
          `Hubo un error al reintentar el reporte para la cuenta ${accountReport.name}`,
          "error"
        );
      });
  };

  return (
    <>
      {accountReport.retrying || retrying ? (
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{ height: "100%" }}
        >
          <Typography>Retriying...</Typography>
        </Stack>
      ) : !accountReport.error ? (
        <IconButton
          aria-label="export"
          color="primary"
          onClick={handleDownload}
        >
          <GetAppIcon />
        </IconButton>
      ) : (
        <IconButton aria-label="retry" color="primary" onClick={retryProcess}>
          <ReplayIcon />
        </IconButton>
      )}
    </>
  );
}
