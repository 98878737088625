import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { Account, AccountType } from "../types/Account";
import axios from "axios";
import { useAccount } from "../providers/AccountContext";
import { useSnackbar } from "../providers/SnackbarContext";
import { useState } from "react";
import { MenuItem } from "@mui/material";
import { useBitrix } from "../providers/BitrixContext";

interface AccountsEditDialogProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  Account: Partial<Account>;
}

export default function AccountsEditDialog({
  open,
  setOpen,
  Account,
}: AccountsEditDialogProps) {
  const { editAccount } = useAccount();
  const { openSnackbar } = useSnackbar();
  const { usersList } = useBitrix();
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const onFormSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    const formData = new FormData(event.currentTarget);
    const formJson = Object.fromEntries((formData as any).entries());
    await axios
      .post(`${process.env.REACT_APP_API_HOST}/account/edit`, {
        id: Account.id,
        ...formJson,
      })
      .then((response) => {
        editAccount(response.data);
        setLoading(false);
        openSnackbar("Se editó una cuenta con éxito.", "success");
        handleClose();
      })
      .catch(() => {
        setLoading(false);
        openSnackbar("Hubo un error al editar la cuenta", "error");
      });
  };

  if (!usersList) {
    return <></>;
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: onFormSubmit,
        }}
      >
        <DialogTitle>Editar Clave Fiscal</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Edita los cambios necesarios para poder acceder a esta clave fiscal
            a través del reporte.
          </DialogContentText>
          <TextField
            required
            margin="dense"
            name="name"
            label="Nombre"
            type="text"
            fullWidth
            variant="standard"
            defaultValue={Account.name}
          />
          <TextField
            required
            select
            margin="dense"
            name="type"
            label="Tipo"
            fullWidth
            variant="standard"
            defaultValue={Account.type}
          >
            <MenuItem value={AccountType.Monotributista}>
              {AccountType.Monotributista}
            </MenuItem>
            <MenuItem value={AccountType.ResponsableInscripto}>
              {AccountType.ResponsableInscripto}
            </MenuItem>
          </TextField>
          <TextField
            required
            margin="dense"
            name="claveFiscal"
            label="Clave Fiscal"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={Account.claveFiscal}
          />
          <TextField
            required
            margin="dense"
            name="password"
            label="Contraseña"
            type="password"
            fullWidth
            variant="standard"
            defaultValue="nonpassword"
          />
          <TextField
            required
            margin="dense"
            name="cuit"
            label="CUIT"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={Account.cuit}
          />
          <TextField
            required
            margin="dense"
            name="alicuota"
            label="Alicuota"
            type="number"
            fullWidth
            variant="standard"
            defaultValue={Account.alicuota}
            inputProps={{ step: "any" }}
          />
          <TextField
            required
            select
            margin="dense"
            name="responsible"
            label="Usuario responsable"
            fullWidth
            variant="standard"
            defaultValue={Account.responsible}
          >
            {usersList
              ?.sort((a, b) => (a.NAME > b.NAME ? 0 : -1))
              .map((u) => (
                <MenuItem value={`${u.NAME} ${u.LAST_NAME}`}>
                  {`${u.NAME} ${u.LAST_NAME}`}
                </MenuItem>
              ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button type="submit" disabled={loading}>
            Editar
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
