import * as React from "react";
import "./AccountsTable.css";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import AccountsTableActionMenu from "./AccountsTableActionMenu";
import { useEffect } from "react";
import axios from "axios";
import { useAccount } from "../providers/AccountContext";

export default function AccountsTable() {
  const { accounts, setAccounts } = useAccount();

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_HOST}/accounts`)
      .then((response) => setAccounts(response.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!accounts) {
    return <p>Cargando...</p>;
  }

  const columns: GridColDef<(typeof accounts)[number]>[] = [
    {
      field: "name",
      headerName: "Nombre",
      minWidth: 240,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Tipo",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "claveFiscal",
      // type: "number",
      headerName: "Clave Fiscal",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "cuit",
      // type: "number",
      headerName: "CUIT",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "alicuota",
      type: "number",
      headerName: "Alicuota",
      minWidth: 80,
      flex: 1,
    },
    {
      field: "responsible",
      headerName: "Responsable",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (params) => <AccountsTableActionMenu Account={params.row} />,
    },
  ];

  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        rows={accounts}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        disableRowSelectionOnClick
        autoHeight
        rowHeight={46}
      />
    </Box>
  );
}
