import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { Account } from "../types/Account";

interface AccountContextProps {
  accounts: Partial<Account>[] | undefined;
  setAccounts: React.Dispatch<
    React.SetStateAction<Partial<Account>[] | undefined>
  >;
  addAccount: (account: Partial<Account>) => void;
  editAccount: (account: Partial<Account>) => void;
  deleteAccount: (id: string) => void;
}

const AccountContext = createContext<AccountContextProps | undefined>(
  undefined
);

export const useAccount = (): AccountContextProps => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccount must be used within a AccountProvider");
  }
  return context;
};

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountProvider = ({ children }: AccountProviderProps) => {
  const [accounts, setAccounts] = useState<Partial<Account>[]>();

  const addAccount = useCallback(
    (account: Partial<Account>) => {
      if (accounts) {
        setAccounts([...accounts, account]);
      }
    },
    [accounts]
  );

  const editAccount = useCallback(
    (account: Partial<Account>) => {
      if (accounts) {
        setAccounts(
          accounts.map((acc) => {
            if (acc.id === account.id) {
              return account;
            } else {
              return acc;
            }
          })
        );
      }
    },
    [accounts]
  );

  const deleteAccount = useCallback(
    (id: string) => {
      if (accounts) {
        setAccounts(
          accounts.reduce(
            (prev: Partial<Account>[], curr) =>
              curr.id === id ? prev : [...prev, curr],
            []
          )
        );
      }
    },
    [accounts]
  );

  const memoizedValue = useMemo(
    () => ({
      accounts,
      setAccounts,
      addAccount,
      editAccount,
      deleteAccount,
    }),
    [accounts, addAccount, deleteAccount, editAccount]
  );

  return (
    <AccountContext.Provider value={memoizedValue}>
      {children}
    </AccountContext.Provider>
  );
};
