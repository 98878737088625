import * as React from "react";
import "./ReportViewTable.css";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarContainer,
  GridToolbarContainerProps,
  GridToolbarExportContainer,
  GridTreeNode,
} from "@mui/x-data-grid";
import { AccountReport, Report } from "../../types/Report";
import ReportViewTableActionMenu from "./ReportViewTableActionMenu";
import * as XLSX from "xlsx-js-style";
import ReportStatusColumn from "./ReportStatusColumn";
import { formatNumberToCurrencyString } from "../../helpers/currency";
import {
  impuestoDeterminadoTotal,
  ivaCreditoFiscal,
  ivaDebitoFiscal,
  saldoIBB,
  saldoIVAEstimado,
  ventas,
} from "../../helpers/afipCalculations";
import { ButtonProps } from "@mui/material";

const csvOptions: GridCsvExportOptions = {
  delimiter: ";",
  utf8WithBom: true,
  escapeFormulas: false,
  fileName: "Reporte General",
};

function CustomExportButton(props: ButtonProps) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props: GridToolbarContainerProps) {
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
    </GridToolbarContainer>
  );
}

interface ReportViewTableProps {
  report: Report;
  handleRefresh: () => void;
}

export default function ReportViewTable({
  report,
  handleRefresh,
}: ReportViewTableProps) {
  const columns: GridColDef<(typeof report.accountReports)[number]>[] = [
    {
      field: "actions",
      headerName: "Actions",
      renderCell: (parameters) => (
        <ReportViewTableActionMenu
          report={report}
          accountReport={parameters.row}
          handleDownload={() => handleDownload(parameters.row)}
          handleRefresh={handleRefresh}
        />
      ),
      disableExport: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      minWidth: 120,
      flex: 1,
      renderCell: (parameters) => (
        <ReportStatusColumn accountReport={parameters.row} />
      ),
      disableExport: true,
      disableColumnMenu: true,
      sortable: false,
    },
    {
      field: "name",
      type: "string",
      headerName: "Nombre",
      minWidth: 240,
      flex: 1,
    },
    {
      field: "claveFiscal",
      headerName: "Clave Fiscal",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "cuit",
      headerName: "CUIT",
      minWidth: 140,
      flex: 1,
    },
    {
      field: "responsible",
      headerName: "Responsable",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "friendlyNotes",
      headerName: "Notas",
      minWidth: 200,
      maxWidth: 200,
    },
    {
      field: "ivaDebitoFiscal",
      headerName: "IVA Déb. Fis.",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => ivaDebitoFiscal(row),
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "ivaCreditoFiscal",
      headerName: "IVA Créd. Fis.",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => ivaCreditoFiscal(row),
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "saldoTecnicoAnt",
      headerName: "Saldo Téc. Ant.",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.data?.saldoTecnico || 0.0,
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "saldoLibreAnt",
      headerName: "Saldo Lib. Ant.",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.data?.saldoLibre || 0.0,
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "saldoIva",
      headerName: "Saldo IVA est.",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => saldoIVAEstimado(row),
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "ventas",
      headerName: "Ventas",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => ventas(row),
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "impuestoDeterminadoTotal",
      headerName: "Imp. Det. Total",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => impuestoDeterminadoTotal(row),
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "saldoAFavorPer",
      headerName: "Saldo a favor",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.data?.saldoMendoza || 0.0,
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "creditosPorSicreb",
      headerName: "Créd. SICREB",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.data?.SICREBTotal || 0.0,
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "creditosPorSirtac",
      headerName: "Créd. SIRTAC",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => row.data?.SIRTACTotal || 0.0,
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
    {
      field: "saldoIBB",
      headerName: "Saldo IIBB",
      type: "number",
      minWidth: 140,
      flex: 1,
      valueGetter: (value, row) => saldoIBB(row),
      renderCell: (params) => <>{formatNumberToCurrencyString(params.value)}</>,
    },
  ];

  const [workbook, setWorkbook] = React.useState<XLSX.WorkBook>();

  const fetchData = async () => {
    const response = await fetch("/reportTemplate.xlsx");
    const arrayBuffer = await response.arrayBuffer();
    const data = new Uint8Array(arrayBuffer);
    const wb = XLSX.read(data, { type: "array" });
    setWorkbook(wb);
  };
  React.useEffect(() => {
    fetchData();
  }, []);

  if (!report) {
    return <p>Cargando...</p>;
  }

  if (!report.accountReports || !report.accountReports.length) {
    return <p>Este reporte no tiene registros</p>;
  }

  const handleDownload = (account: AccountReport) => {
    if (!workbook) {
      alert("Archivo no cargado.");
      return;
    }

    // Emitidos y Recibidos details
    const header = [
      "Fecha",
      "Tipo",
      "Número",
      "Doc. Receptor",
      "Denominación receptor",
      "Imp. Neto Gravado",
      "Imp. Neto no Gravado",
      "Imp. Op. Ext.",
      "Otros Tributos",
      "IVA",
      "Imp. Total",
    ];
    const emitidosWorksheet = XLSX.utils.aoa_to_sheet([
      header,
      ...account.data.detalleEmitidos.map((details) => [
        details.fecha,
        details.tipo,
        details.numero,
        details.docReceptor,
        details.denominacionReceptor,
        details.impNetoGravado,
        details.impNetoNoGravado,
        details.impOpExentas,
        details.otrosTributos,
        details.iva,
        details.impTotal,
      ]),
    ]);
    const recibidosWorksheet = XLSX.utils.aoa_to_sheet([
      header,
      ...account.data.detalleRecibidos.map((details) => [
        details.fecha,
        details.tipo,
        details.numero,
        details.docReceptor,
        details.denominacionReceptor,
        details.impNetoGravado,
        details.impNetoNoGravado,
        details.impOpExentas,
        details.otrosTributos,
        details.iva,
        details.impTotal,
      ]),
    ]);

    //Column sizes for detailed sheets
    var wscols = [
      { wch: 11.5 },
      { wch: 29.67 },
      { wch: 17 },
      { wch: 17.17 },
      { wch: 36.5 },
      { wch: 16.67 },
      { wch: 16.67 },
      { wch: 16.67 },
      { wch: 16.67 },
      { wch: 16.67 },
    ];
    emitidosWorksheet["!cols"] = wscols;
    recibidosWorksheet["!cols"] = wscols;
    XLSX.utils.book_append_sheet(workbook, emitidosWorksheet, "Emitidos");
    XLSX.utils.book_append_sheet(workbook, recibidosWorksheet, "Recibidos");

    // General report
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];

    //Column sizes
    var wscols = [
      { wch: 10 },
      { wch: 37.5 },
      { wch: 15.83 },
      { wch: 14.67 },
      { wch: 14.67 },
      { wch: 18.33 },
      { wch: 10 },
      { wch: 10 },
      { wch: 20.17 },
      { wch: 19.67 },
      { wch: 25.67 },
      { wch: 26.67 },
      { wch: 30.83 },
      { wch: 33.17 },
      { wch: 33.17 },
    ];
    worksheet["!cols"] = wscols;

    // Colors =========================
    // 92D050
    worksheet["C3"].s = {
      fill: { fgColor: { rgb: "92D050" } },
    };
    worksheet["C4"].s = {
      fill: { fgColor: { rgb: "92D050" } },
    };
    // F2F2F2 Emitidos
    worksheet["B7"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["C7"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["D7"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["E7"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["F7"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    // FFFF00 Emitidos totales
    worksheet["B19"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
    };
    worksheet["C19"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    worksheet["D19"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    worksheet["E19"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    worksheet["F19"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    // F2F2F2 Recibidos
    worksheet["B25"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["C25"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["D25"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["E25"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["F25"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    // FFFF00 Recibidos totales
    worksheet["B37"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
    };
    worksheet["C37"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    worksheet["D37"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    worksheet["E37"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    worksheet["F37"].s = {
      fill: { fgColor: { rgb: "FFFF00" } },
      font: { color: { rgb: "FF0000" }, bold: true },
      numFmt: "$#,###.00",
    };
    // F2F2F2 Reporte IVA header
    worksheet["C41"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["D41"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["E41"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["F41"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    // F2F2F2 Reporte IIBB Header
    worksheet["C50"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["D50"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["E50"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    // 92D050 Important numbers
    worksheet["E44"].s = {
      fill: { fgColor: { rgb: "92D050" } },
      numFmt: "$#,###.00",
    };
    worksheet["E45"].s = {
      fill: { fgColor: { rgb: "92D050" } },
      numFmt: "$#,###.00",
    };
    worksheet["C55"].s = {
      fill: { fgColor: { rgb: "92D050" } },
      numFmt: "$#,###.00",
    };
    worksheet["C56"].s = {
      fill: { fgColor: { rgb: "92D050" } },
      numFmt: "$#,###.00",
    };
    worksheet["C57"].s = {
      fill: { fgColor: { rgb: "92D050" } },
      numFmt: "$#,###.00",
    };
    // F2F2F2 Business resume
    worksheet["I7"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    worksheet["I17"].s = {
      fill: { fgColor: { rgb: "F2F2F2" } },
      font: { bold: true },
    };
    // Bold headers
    worksheet["B40"].s = {
      font: { bold: true },
    };
    worksheet["B49"].s = {
      font: { bold: true },
    };

    // Header
    worksheet["C3"].v = account.cuit;
    worksheet["C4"].v = account.name;

    // Formatos ===================================
    worksheet["C8"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D8"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E8"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F8"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C9"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D9"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E9"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F9"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C10"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D10"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E10"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F10"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C11"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D11"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E11"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F11"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C12"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D12"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E12"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F12"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C13"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D13"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E13"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F13"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C14"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D14"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E14"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F14"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C15"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D15"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E15"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F15"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C16"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D16"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E16"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F16"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C17"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D17"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E17"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F17"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C18"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D18"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E18"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F18"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F20"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C26"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D26"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E26"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F26"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C27"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D27"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E27"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F27"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C28"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D28"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E28"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F28"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C29"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D29"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E29"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F29"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C30"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D30"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E30"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F30"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C31"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D31"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E31"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F31"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C32"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D32"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E32"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F32"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C33"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D33"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E33"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F33"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C34"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D34"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E34"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F34"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C35"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D35"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E35"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F35"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C36"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["D36"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["E36"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F36"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["F38"].s = {
      numFmt: "$#,###.00",
    };
    worksheet["C42"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["D42"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["E42"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["F42"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["C43"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["D43"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["E43"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["F43"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["E46"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["C51"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["D51"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["E51"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["C53"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["D53"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["C54"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["C58"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["J7"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["K7"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["L7"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["M7"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["N7"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["J17"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["K17"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["L17"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["M17"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["N17"].s = {
      numFmt: "$#,##.00",
    };
    worksheet["O17"].s = {
      numFmt: "$#,##.00",
    };

    // Valores =====================================
    // Facturas de exportacion
    worksheet["F20"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("19 -"))?.impTotal ||
      0;

    worksheet["F38"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("19 -"))?.impTotal ||
      0;

    // Emitidos
    // Grupo
    worksheet["C8"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura A"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("201 -"))
        ?.impNetoGravado || 0);
    worksheet["D8"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura A"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("201 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E8"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura A"))?.iva ||
        0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("201 -"))?.iva || 0);
    worksheet["F8"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura A"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("201 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C9"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura B"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("206 -"))
        ?.impNetoGravado || 0);
    worksheet["D9"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura B"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("206 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E9"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura B"))?.iva ||
        0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("206 -"))?.iva || 0);
    worksheet["F9"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura B"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("206 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C10"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura C"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("211 -"))
        ?.impNetoGravado || 0);
    worksheet["D10"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura C"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("211 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E10"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura C"))?.iva ||
        0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("211 -"))?.iva || 0);
    worksheet["F10"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Factura C"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("211 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C11"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Factura T"))
        ?.impNetoGravado || 0;
    worksheet["D11"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Factura T"))
        ?.impNetoNoGravado || 0;
    worksheet["E11"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Factura T"))?.iva ||
      0;
    worksheet["F11"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Factura T"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C12"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("202 -"))
        ?.impNetoGravado || 0);
    worksheet["D12"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("202 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E12"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.iva || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("202 -"))?.iva || 0);
    worksheet["F12"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("202 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C13"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("207 -"))
        ?.impNetoGravado || 0);
    worksheet["D13"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("207 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E13"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.iva || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("207 -"))?.iva || 0);
    worksheet["F13"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("207 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C14"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("212 -"))
        ?.impNetoGravado || 0);
    worksheet["D14"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("212 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E14"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.iva || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("212 -"))?.iva || 0);
    worksheet["F14"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("212 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C15"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("203 -"))
        ?.impNetoGravado || 0);
    worksheet["D15"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("203 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E15"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.iva || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("203 -"))?.iva || 0);
    worksheet["F15"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("203 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C16"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("208 -"))
        ?.impNetoGravado || 0);
    worksheet["D16"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("208 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E16"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.iva || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("208 -"))?.iva || 0);
    worksheet["F16"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("208 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C17"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.impNetoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("213 -"))
        ?.impNetoGravado || 0);
    worksheet["D17"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.impNetoNoGravado || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("213 -"))
        ?.impNetoNoGravado || 0);
    worksheet["E17"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.iva || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("213 -"))?.iva || 0);
    worksheet["F17"].v =
      (account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.impTotal || 0) +
      (account.data?.emitidos?.find((d) => d.tipo.includes("213 -"))
        ?.impTotal || 0);
    // Grupo
    worksheet["C18"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.impNetoGravado || 0;
    worksheet["D18"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.impNetoNoGravado || 0;
    worksheet["E18"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.iva || 0;
    worksheet["F18"].v =
      account.data?.emitidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.impTotal || 0;

    // Recibidos
    // Grupo
    worksheet["C26"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura A"))
        ?.impNetoGravado || 0;
    worksheet["D26"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura A"))
        ?.impNetoNoGravado || 0;
    worksheet["E26"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura A"))?.iva ||
      0;
    worksheet["F26"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura A"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C27"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura B"))
        ?.impNetoGravado || 0;
    worksheet["D27"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura B"))
        ?.impNetoNoGravado || 0;
    worksheet["E27"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura B"))?.iva ||
      0;
    worksheet["F27"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura B"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C28"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura C"))
        ?.impNetoGravado || 0;
    worksheet["D28"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura C"))
        ?.impNetoNoGravado || 0;
    worksheet["E28"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura C"))?.iva ||
      0;
    worksheet["F28"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura C"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C29"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura T"))
        ?.impNetoGravado || 0;
    worksheet["D29"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura T"))
        ?.impNetoNoGravado || 0;
    worksheet["E29"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura T"))?.iva ||
      0;
    worksheet["F29"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Factura T"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C30"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.impNetoGravado || 0;
    worksheet["D30"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.impNetoNoGravado || 0;
    worksheet["E30"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.iva || 0;
    worksheet["F30"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito A"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C31"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.impNetoGravado || 0;
    worksheet["D31"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.impNetoNoGravado || 0;
    worksheet["E31"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.iva || 0;
    worksheet["F31"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito B"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C32"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.impNetoGravado || 0;
    worksheet["D32"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.impNetoNoGravado || 0;
    worksheet["E32"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.iva || 0;
    worksheet["F32"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Débito C"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C33"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.impNetoGravado || 0;
    worksheet["D33"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.impNetoNoGravado || 0;
    worksheet["E33"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.iva || 0;
    worksheet["F33"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito A"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C34"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.impNetoGravado || 0;
    worksheet["D34"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.impNetoNoGravado || 0;
    worksheet["E34"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.iva || 0;
    worksheet["F34"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito B"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C35"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.impNetoGravado || 0;
    worksheet["D35"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.impNetoNoGravado || 0;
    worksheet["E35"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.iva || 0;
    worksheet["F35"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito C"))
        ?.impTotal || 0;
    // Grupo
    worksheet["C36"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.impNetoGravado || 0;
    worksheet["D36"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.impNetoNoGravado || 0;
    worksheet["E36"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.iva || 0;
    worksheet["F36"].v =
      account.data?.recibidos?.find((d) => d.tipo.includes("Nota de Crédito T"))
        ?.impTotal || 0;

    // Numbers
    worksheet["E44"].v = account.data?.saldoTecnico || 0;
    worksheet["E45"].v = account.data?.saldoLibre || 0;
    worksheet["C55"].v = account.data?.saldoMendoza || 0;
    worksheet["C56"].v = account.data?.SICREBTotal || 0;
    worksheet["C57"].v = account.data?.SIRTACTotal || 0;
    worksheet["C52"].v = (account.alicuota / 100) * -1;
    worksheet["D52"].v = (account.alicuota / 100) * -1;

    // Business resume
    worksheet["I7"].v = account.name;
    worksheet["I17"].v = account.name;

    // Generar un archivo Excel y descargarlo
    const newWorkbook = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const blob = new Blob([newWorkbook], { type: "application/octet-stream" });

    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = `Reporte-${account.name}-${report.reportPeriod}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    fetchData();
  };

  return (
    <>
      <Box sx={{ width: "100%", height: 420 }}>
        <DataGrid
          // slots={{ toolbar: GridToolbar }}
          slotProps={{
            toolbar: { printOptions: { disableToolbarButton: true } },
          }}
          slots={{ toolbar: CustomToolbar }}
          rows={report.accountReports.filter((i) => i)}
          getRowId={(row) => row.cuit}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 20,
              },
            },
          }}
          pageSizeOptions={[20]}
          disableRowSelectionOnClick
          rowHeight={60}
          getCellClassName={(
            params: GridCellParams<
              any,
              AccountReport,
              AccountReport,
              GridTreeNode
            >
          ) => {
            if (params.field === "saldoIva" || params.field === "saldoIBB") {
              return "highlight";
            }
            return "";
          }}
          sx={{
            "& .MuiDataGrid-columnHeaders": {
              position: "sticky",
              top: 0,
              zIndex: 1,
              backgroundColor: "white", // Cambia el color de fondo si es necesario
            },
          }}
        />
      </Box>
    </>
  );
}
