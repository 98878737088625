export type MisComprobantesData = {
  tipo: string;
  iva: number;
  impNetoGravado: number;
  impNetoNoGravado: number;
  impTotal: number;
};

export type MisComprobantesDetails = {
  fecha: string;
  tipo: string;
  numero: string;
  docReceptor: string;
  denominacionReceptor: string;
  impNetoGravado: number;
  impNetoNoGravado: number;
  impOpExentas: number;
  otrosTributos: number;
  iva: number;
  impTotal: number;
};

export type AccountReport = {
  claveFiscal: number;
  cuit: number;
  name: string;
  alicuota: number;
  data: {
    saldoTecnico: number;
    saldoLibre: number;
    saldoMendoza: number;
    SICREBTotal: number;
    SIRTACTotal: number;
    emitidos: MisComprobantesData[];
    recibidos: MisComprobantesData[];
    detalleEmitidos: MisComprobantesDetails[];
    detalleRecibidos: MisComprobantesDetails[];
  };
  error?: boolean;
  errorMessage?: string;
  retrying?: boolean;
  responsible: string;
  friendlyNotes?: string;
};

export enum ReportStatus {
  WIP = "En progreso",
  READY = "Listo",
  ERROR = "Error",
}

export type Report = {
  _id: string;
  status: ReportStatus;
  accountReports: AccountReport[];
  createdAt: string;
  notes: string;
  reportPeriod: string;
};
