import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import AccountsPage from "./Accounts/AccountsPage";
import ReportsPage from "./Reports/ReportsPage";
import reportWebVitals from "./reportWebVitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { SnackbarProvider } from "./providers/SnackbarContext";
import { AccountProvider } from "./providers/AccountContext";
import Layout from "./Layout";
import { BitrixProvider } from "./providers/BitrixContext";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <ReportsPage />,
      },
      {
        path: "accounts",
        element: <AccountsPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  // <React.StrictMode>
  <BitrixProvider>
    <SnackbarProvider>
      <AccountProvider>
        <RouterProvider router={router} />
      </AccountProvider>
    </SnackbarProvider>
  </BitrixProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
