import { IconButton } from "@mui/material";
import { Report } from "../types/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import GetAppIcon from "@mui/icons-material/GetApp";

interface ReportsTableActionMenuProps {
  Report: Partial<Report>;
  handleOpenView: () => void;
}

export default function ReportsTableActionMenu({
  Report,
  handleOpenView,
}: ReportsTableActionMenuProps) {
  return (
    <>
      <IconButton aria-label="view" color="primary" onClick={handleOpenView}>
        <VisibilityIcon />
      </IconButton>
      {/* <IconButton
        aria-label="export"
        color="primary"
        // onClick={() => setDeleteDialogOpen(true)}
      >
        <GetAppIcon />
      </IconButton> */}
    </>
  );
}
